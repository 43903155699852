import { Head, useForm, usePage } from '@inertiajs/react';
import Typography from '../../components/Typography';
import AuthenticationLayout from '@/components/layouts/AuthenticationLayout';
import Button from '../../components/Button';
import LoadingOverlay from '../../components/overlays/LoadingOverlay.jsx';
import InvestorRegisterForm from '@/components/forms/InvestorApplicationForm';
import Box from '@/components/Box';
import Panel from '@/components/Panel';
import asset from '@/utils/VaporUtil.js';
import { useDialog } from '@/contexts/DialogContext.jsx';
import styled from 'styled-components';
import { useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const InvestorApplication = ({ default_values, turnstile_sitekey, redirect_url }) => {
    const form = useForm(default_values);
    const presets = usePage().props.presets;
    const dialog = useDialog();

    useEffect(() => {
        turnstile.ready(function () {
            const widgetId = turnstile.render('#turnstile-challenge', {
                sitekey: turnstile_sitekey,
                appearance: 'interaction-only',
                callback: function (token) {
                    form.setData((previousData) => ({
                        ...previousData,
                        'cf-turnstile-response': token,
                    }));
                },
            });

            form.setData((previousData) => ({ ...previousData, widget_id: widgetId }));
        });
    }, []);

    const handleInvestorRegister = () => {
        form.submit('post', route('investor_application.store'), {
            preserveScroll: true,
            onSuccess: () => {
                form.reset();

                if (redirect_url) {
                    window.top.location.href = redirect_url;
                } else {
                    dialog.openDialog('success', {
                        title: 'Aangemeld als investeerder!',
                        description: 'U bent succesvol geregistreerd als investeerder. U ontvangt een e-mail om uw e-mailadres te verifiëren.',
                    });
                }
            },
            onFinish: () => {
                turnstile.reset(form.data.widget_id);
            },
        });
    };

    return (
        <>
            <Head title={'Aanmelden als investeerder'} />

            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'calc(100vh - 80px)'}>
                <StyledPatrone src={asset('assets/images/patrone.png')} />

                <Panel className={'relative w-full max-w-4xl'}>
                    <LoadingOverlay loading={form.processing} />

                    <img className={'h-10 w-fit'} src={asset('assets/images/logo/black.svg')} alt={'Capilex'} />

                    <Typography fontSize={'lg'} mt={40}>
                        Aanmelden als investeerder
                    </Typography>

                    <Typography color={'grey.9'}>U kunt bij Capilex veilig investeren in Nederlandse MKB-ondernemingen, met een korte looptijd, aantrekkelijk rendement en vastgoed als onderpand. Vul hieronder uw gegevens in om u aan te melden.</Typography>

                    <InvestorRegisterForm form={form} presets={presets} turnstile_sitekey={turnstile_sitekey} mt={24} />

                    <div className={'flex flex-col gap-2'}>
                        <div id={'turnstile-challenge'} className={'mt-6 w-full'}></div>
                    </div>

                    <Button onClick={handleInvestorRegister} className={'mt-5'}>
                        <span className={'font-bold'}>Aanmelden</span>
                        <ArrowRightIcon width={32} />
                    </Button>
                </Panel>
            </Box>
        </>
    );
};

const StyledPatrone = styled.img`
    position: fixed;
    bottom: -5vw;
    right: -5vw;
    width: 45vh;
    aspect-ratio: 1;
    opacity: 0.075;
    user-select: none;
    z-index: 0;
`;

InvestorApplication.layout = (children) => <AuthenticationLayout children={children} />;

export default InvestorApplication;
